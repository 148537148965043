import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
const queryString = require("query-string");


export const getSocailStats = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/social-media-list`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const addSocailStats = async (exchangeList: {
  name: string;
  base_url: string;
  logo: string;
}) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/social-media-list`);
  const response = (await baseRequest.post(url, exchangeList)) as any;
  return await response?.json();
};

export const updateSocailStatsbyID = async (
  socailList:any,
  Id: number
) => {
  console.log('api response data,', socailList)
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/social-media-list/${Id}`);
  const response = (await baseRequest.patch(url, socailList)) as any;
  return await response?.json();
};

export const getSocailStatsbyID = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/social-media-list/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const deleteSocailStatsbyID = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/social-media-list/${Id}`);
  const response = (await baseRequest.delete(url,{})) as any;
  return await response?.json();
};



