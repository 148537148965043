import React, { useState } from "react";
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import useStyles from "../../style";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "antd";
import moment from "moment";
import CurrencyInputWithValidate from "../CurrencyInputWithValidate";
import { DATETIME_FORMAT, UTC_TIMEZONE } from "../../../../constants";
import {
  fieldMustBeGreaterThanZero,
  fieldMustBeLessThanHundred,
  renderErrorCreatePool,
} from "../../../../utils/validate";
import {
  convertDateTimeStringToMomentObject,
  convertMomentObjectToDateTimeString,
  makeLocalTimeInUTC,
} from "../../../../utils/convertDate";
import { alertSuccess } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";

function CreateEditClaimConfigForm(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    editData,
    isEdit,
    handleCreateUpdateData,
  } = props;
  const renderError = renderErrorCreatePool;
  console.log("editData", editData);
  const {
    register,
    setValue,
    getValues,
    clearErrors,
    errors,
    handleSubmit,
    control,
    formState: { touched, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...editData,
      // Convert startTime from "2021-05-28 08:45:59" to Moment Object
      startTime:
        isEdit && editData.startTime
          ? convertDateTimeStringToMomentObject(editData.startTime)
          : null,
    },
  });

  const submitData = (data: any) => {
    const responseData = {
      // Convert startTime from Moment Object to String "2021-05-28 08:45:59"
      // startTime: data.startTime.format(DATETIME_FORMAT),
      startTime: convertMomentObjectToDateTimeString(data.startTime),
      maxBuy: data.maxBuy,
    };
    handleCreateUpdateData && handleCreateUpdateData(responseData);
  };

  const handleSubmitPopup = () => {
    return handleSubmit(submitData)().then((res) => {
      console.log("Res: ", isValid, errors);
      if (isValid) {
        clearErrors();
      }
    });
  };

  const [isInitailDateShift,setIsInitailDateShift] = useState({
    startTime:false,
  })

  function handleDateSelection(field: any, date: any, validationObj: any = {}) {
    if (UTC_TIMEZONE) {
      const utcDate = date.toISOString();
      setValue(field, moment(utcDate), validationObj);
    } else {
      setValue(field, date, validationObj);
      
    }
  }

  const renderUtcSpan = () =>
    UTC_TIMEZONE && (
      <span style={{ fontWeight: "bold", fontSize: "12px" }}>UTC</span>
    );

  return (
    <>
      <ConfirmDialog
        title={isEdit ? "Edit" : "Create"}
        open={isOpenEditPopup}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
          clearErrors();
        }}
        // btnLoading={true}
      >
        {/*{isEdit &&*/}
        {/*  <div className={classes.formControl}>*/}
        {/*    <label className={classes.formControlLabel}>Record No.</label>*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      value={editData.id}*/}
        {/*      className={classes.formControlInput}*/}
        {/*      disabled={true}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*}*/}

        <div className={classes.formControl}>
        <div style={{ display: "flex", flexDirection: "column",marginBottom:'4px' }}>
          <label className={classes.formControlLabel}>Start Time</label>
          {renderUtcSpan()}
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="startTime"
              render={(field) => {
                const selectedDate = field.value ? new Date(field.value) : null;
                return (
                  <DatePicker
                    {...field}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{
                      defaultValue: moment("00:00:00", "HH:mm:ss"),
                      format: "HH:mm",
                    }}
                    inputReadOnly={UTC_TIMEZONE}
                    value={
                      selectedDate
                        ? UTC_TIMEZONE
                          ? moment(selectedDate).utc()
                          : moment(selectedDate)
                        : null
                    }

                    
                    onSelect={(datetimeSelected) => {
                      if (
                        UTC_TIMEZONE &&
                        !getValues("startTime") &&
                        !isInitailDateShift.startTime
                      ) {
                        handleDateSelection(
                          field.name,
                          moment(datetimeSelected).add(1, "day"),{
                            shouldValidate: true,
                          }
                        );
                        setIsInitailDateShift((prevState) => ({
                          ...prevState,
                          startTime: true,
                        }));
                      } else {
                        handleDateSelection(field.name, datetimeSelected,{
                          shouldValidate: true,
                        });
                      }
                    }}
                    // onSelect={(datetimeSelected) =>
                    // {
                    //   handleDateSelection(field.name, datetimeSelected, {
                    //     shouldValidate: true,
                    //   })
                    // }}
                    minuteStep={5}
                  />
                );
              }}
            />
          </div>
          <p className={classes.formErrorMessage}>
            {renderError(errors, "startTime")}
          </p>
        </div>

        <div className={classes.formControl}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "4px",
            }}
          >
            <label className={classes.formControlLabel}>Max Claim (%)</label>
            {/* {renderUtcSpan()} */}
          </div>
          <div>
            <CurrencyInputWithValidate
              register={register}
              errors={errors}
              initValue={editData.maxBuy}
              onInput={(e: any) => {
                const value = parseFloat(e.target.value);

                if (value > 100) {
                  e.target.value = 100;
                }
              }}
              controlName={"maxBuy"}
              validateRule={{
                required: true,
                validate: {
                  maxBuyGreaterThanZero: fieldMustBeGreaterThanZero,
                  maxBuyLessThanHundred: fieldMustBeLessThanHundred,
                },
              }}
            />
          </div>
          <p className={classes.formErrorMessage}>
            {renderError(errors, "maxBuyGreaterThanZero")}
            {renderError(errors, "maxBuyLessThanHundred")}
          </p>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default CreateEditClaimConfigForm;
