import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { FormControl, Switch } from "@material-ui/core";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { getFlag, updateSortStatus } from "../../request/config";
import EnableROIForm from "./EnableROIForm";
import EnableSolanaFeatureForm from "./EnableSolanaFeatureForm";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import EnableCurrentROIForm from "./EnableCurrentROI";
import EnableMaxCap from "./EnableMaxCapForm";
import EnableKycForm from "./EnableKycForm";
import { MAX_CAP, STAKING_POOL_CACHE } from "../../constants";
import EnableSwapDisableFeatureForm from "./EnableSwapDisableFeatureForm";
import StakingCachingForm from "./StakingCachingForm";

const Config = () => {
  const dispatch = useDispatch();
  const [sortFlag, setSortFlag] = useState(true);
  const [flag, setFlag] = useState("");
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [displayLabel, setDisplayLabel] = useState(true);
  const [localFlag, setLocalFlag] = useState(true);

  const [solnaFeatFlag, setSolanaFeatFlag] = useState(true);
  const [solanaFlag, setSolanaFlag] = useState("");

  const [swapFeatFlag, setSwapFeatFlag] = useState(true);
  const [swapFlag, setSwapFlag] = useState("");

  const [cachingFeatFlag, setCachingFeatFlag] = useState(true);
  const [cachingFlag, setCachingFlag] = useState("");

  const [isOpenEditPopupSwap, setIsOpenEditPopupSwap] =
    useState<boolean>(false);
  const [displaySwapLabel, setDisplaySwapLabel] = useState(true);
  const [localSwapFlag, setLocalSwapFlag] = useState(true);

  const [localCachingFlag, setLocalCachingFlag] = useState(true);
  const [isOpenEditPopupCaching, setIsOpenEditPopupCaching] =
    useState<boolean>(false);
  const [displayCachingLabel, setDisplayCachingLabel] = useState(true);

  const [isOpenEditPopupSolana, setIsOpenEditPopupSolana] =
    useState<boolean>(false);
  const [displaySoalnaLabel, setDisplaySolanaLabel] = useState(true);
  const [localSolanaFlag, setLocalSolanaFlag] = useState(true);

  const handleSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    // setSortFlag(value);
    setLocalFlag(value);
    setIsOpenEditPopup(true);
    setDisplayLabel(checked);
  };

  const handleSolanaSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    // setSortFlag(value);
    setLocalSolanaFlag(value);
    setIsOpenEditPopupSolana(true);
    setDisplaySolanaLabel(checked);
  };

  const handleSwapDisableChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    setLocalSwapFlag(value);
    setIsOpenEditPopupSwap(true);
    setDisplaySwapLabel(checked);
  };

  const handleStakingCachingChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    setLocalCachingFlag(value);
    setIsOpenEditPopupCaching(true);
    setDisplayCachingLabel(checked);
  };

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const solanaFlag = flags?.find((flag: any) => flag?.name === "solana");
      const sortFlag = flags?.find(
        (flag: any) => flag?.name === "COMPLETED_POOL_ROI_SORT_ENABLED"
      );
      const disableSwapFlag = flags?.find(
        (flag: any) => flag?.name === "Disable-Claim-On-Kyc"
      );
      const disableCachingFlag = flags?.find(
        (flag: any) => flag?.name === "Staking_Pools_Data"
      );
      if (response?.status === 200) {
        setFlag(sortFlag);
        setSortFlag(sortFlag.value);
        setSolanaFlag(solanaFlag);
        setSolanaFeatFlag(solanaFlag.value);
        setSwapFeatFlag(disableSwapFlag.value);
        setSwapFlag(disableSwapFlag);
        setCachingFeatFlag(disableCachingFlag.value);
        setCachingFlag(disableCachingFlag);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const isViewConfigPage = UseCheckPermissions("VIEW_CONFIG_SECTION");
  const isEditConfigPage = UseCheckPermissions("EDIT_CONFIG_SECTION");
  return (
    <DefaultLayout>
      {isViewConfigPage ? (
        <div>
          <EnableROIForm
            fetchFlags={fetchFlags}
            flag={flag}
            isOpenEditPopup={isOpenEditPopup}
            setIsOpenEditPopup={setIsOpenEditPopup}
            sortFlag={sortFlag}
            setSortFlag={setSortFlag}
            displayLabel={displayLabel}
            localFlag={localFlag}
          />
          <EnableSolanaFeatureForm
            fetchFlags={fetchFlags}
            solanaFlag={solanaFlag}
            isOpenEditPopupSolana={isOpenEditPopupSolana}
            setIsOpenEditPopupSolana={setIsOpenEditPopupSolana}
            solnaFeatFlag={solnaFeatFlag}
            setSolanaFeatFlag={setSolanaFeatFlag}
            displaySoalnaLabel={displaySoalnaLabel}
            localSolanaFlag={localSolanaFlag}
          />
          <EnableSwapDisableFeatureForm
            fetchFlags={fetchFlags}
            swapFlag={swapFlag}
            isOpenEditPopupSwap={isOpenEditPopupSwap}
            setIsOpenEditPopupSwap={setIsOpenEditPopupSwap}
            swapFeatFlag={swapFeatFlag}
            setSwapFeatFlag={setSwapFeatFlag}
            displaySwapLabel={displaySwapLabel}
            localSwapFlag={localSwapFlag}
          />

          <StakingCachingForm
            fetchFlags={fetchFlags}
            swapFlag={cachingFlag}
            isOpenEditPopupSwap={isOpenEditPopupCaching}
            setIsOpenEditPopupSwap={setIsOpenEditPopupCaching}
            swapFeatFlag={cachingFeatFlag}
            setSwapFeatFlag={setCachingFeatFlag}
            displaySwapLabel={displayCachingLabel}
            localSwapFlag={localCachingFlag}
          />

          <EnableCurrentROIForm />
          {MAX_CAP && <EnableMaxCap />}
          <EnableKycForm />

          <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              onChange={(e) => handleSwitchChange(e)}
              checked={sortFlag ? true : false}
              disabled={!isEditConfigPage}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Enable ROI Sorting
            </h4>
          </FormControl>
          <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              onChange={(e) => handleSolanaSwitchChange(e)}
              checked={solnaFeatFlag ? true : false}
              disabled={!isEditConfigPage}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Enable Solana Feature
            </h4>
          </FormControl>
          <FormControl
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Switch
              onChange={(e) => handleSwapDisableChange(e)}
              checked={swapFeatFlag ? true : false}
              disabled={!isEditConfigPage}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Disable Claim for Forbidden Countries
            </h4>
          </FormControl>

          {STAKING_POOL_CACHE && (
            <FormControl
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Switch
                onChange={(e) => handleStakingCachingChange(e)}
                checked={cachingFeatFlag ? true : false}
                disabled={!isEditConfigPage}
              />
              <h4
                style={{
                  marginRight: "10px",
                  marginTop: "5px",
                  fontSize: "14px",
                }}
              >
                Staking Pools Caching
              </h4>
            </FormControl>
          )}
        </div>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default Config;
