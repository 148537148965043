import React, { useState, useEffect, useMemo } from "react";
import useStyles from "../style";
import { useCommonStyle } from "../../../styles";
import {
  etherscanRoute,
  getPoolMetadata,
  getPoolMetadataPda,
} from "../../../utils";
import Link from "@material-ui/core/Link";
import BigNumber from "bignumber.js";

function SolanaPoolHash(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const { poolDetail, duoNetworkPool, watch } = props;

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [poolInfo, setPoolInfo] = useState<any | null>(null);
  const [isWrongAddress, setIsWrongAddress] = useState<boolean>(false);

  const tokenAddressInputed = useMemo(() => watch("token"), [watch]);

  useEffect(() => {
    if (!tokenAddressInputed || !poolInfo?.tokenAddress) return;
    setIsWrongAddress(
      tokenAddressInputed.toLowerCase().trim() !==
        poolInfo?.tokenAddress.toLowerCase().trim()
    );
  }, [watch, poolInfo?.tokenAddress, tokenAddressInputed]);

  const loadPoolInfo = async (poolNetwork: string) => {
    if (!poolNetwork) {
      setPoolInfo(null);
      setLoadingInfo(false);
      return;
    }
    try {
      setPoolInfo(null);
      setLoadingInfo(true);

      const tokenSoldResponse = await getPoolMetadata(poolDetail?.id);
      const tokenSold = tokenSoldResponse.tokenSold.toString();

      setLoadingInfo(false);
      setPoolInfo({
        tokenSold,
      });
    } catch (err) {
      setLoadingInfo(false);
    }
  };

  useEffect(() => {
    loadPoolInfo(poolDetail.network_available);
  }, [poolDetail]);

  const [transactionHash, setTransactionHash] = useState("");

  useEffect(() => {
    const getPoolMetadata = async () => {
      if (!poolDetail) return;

      try {
        const poolData = await getPoolMetadataPda(poolDetail?.pool_index);
        setTransactionHash(poolData);
      } catch (error) {
        console.error("Error while getting Pool Metadata:", error);
      }
    };

    getPoolMetadata();
  }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          {duoNetworkPool
            ? `Pool Buying Address (Please deposit fake token for buying) - ${poolDetail?.network_available}`
            : `Pool Contract Address (Please deposit token to campaign smart contract address) - ${poolDetail?.network_available}`}
        </label>
        <div className={commonStyle.boldText}>
          {!!poolDetail?.is_deploy && (
            <Link
              href={`https://solscan.io/account/${transactionHash}`}
              target={"_blank"}
            >
              {transactionHash && transactionHash}
            </Link>
          )}
          {!poolDetail?.is_deploy && "--"}
        </div>
        {poolInfo && !loadingInfo && !!poolDetail?.is_deploy && (
          <div className={classes.poolInfo}>
            <div className="poolInfoBlock">
              <span className="poolInfoLabel">Token (from contract)</span>
              <div className="poolInfoContent">
                <p className="poolInfoText wordBreak">{poolDetail?.token}</p>
              </div>
            </div>
            <div className="poolInfoBlock"></div>
            <div className="poolInfoBlock">
              <span className="poolInfoLabel">Token sold (wei)</span>
              <div className="poolInfoContent">
                <p className="poolInfoText wordBreak">
                  {parseInt(poolInfo?.tokenSold, 16).toString()}
                </p>
              </div>
            </div>
          </div>
        )}
        {isWrongAddress && !loadingInfo && (
          <div style={{ color: "red" }}>
            The input token address does not match the token address from the
            contract.
          </div>
        )}
      </div>
    </>
  );
}

export default SolanaPoolHash;
