import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "../style";
import { getExchangeList } from "../../../request/exchange-list";
import { alertFailure } from "../../../store/actions/alert";
import {
  Button,
  Card,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { renderError } from "../../../utils/validate";
import CloseIcon from "@material-ui/icons/Close";

function PoolExchangeConnect(props: any) {
  const {
    register,
    setValue,
    errors,
    control,
    getValues,
    reset,
    showTab,
    watch,
    poolDetail,
  } = props;

  const [exchangeList, setExchangeList] = useState<any[]>([]);
  const [selectedExchange, setSelectedExchange] = useState<any>(null);
  const [showExchangeLabels, setShowExchangeLabels] = useState<any[]>([]);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  console.log(selectedExchange);

  useEffect(() => {
    fetchExchangesUrlStoreInDb();
  }, [poolDetail]);

  useEffect(() => {
    register("exchangeUrlDataArray");
  }, [showExchangeLabels]);

  useEffect(() => {
    updateExchangeUrlDataArray();
  }, [showExchangeLabels, setShowExchangeLabels, setValue]);

  const fetchData = async () => {
    try {
      const response = await getExchangeList();
      if (response?.status === 200) {
        setExchangeList(response?.data);
      } else {
        console.error("Failed to fetch exchange list:", response);
      }
    } catch (error) {
      console.error("Error fetching exchange list:", error);
    }
  };

  const fetchExchangesUrlStoreInDb = () => {
    if (poolDetail?.campaignExchangeUrls) {
      const fetchExchanges = poolDetail?.campaignExchangeUrls?.map(
        (item: any) => ({
          exchange_url_id: item?.exchangeUrl?.id,
          endpoint: item?.endpoint || "",
          exchange_name: item?.exchangeUrl?.name,
        })
      );
      setShowExchangeLabels(fetchExchanges);
    }
  };

  const handleAddExchange = () => {
    const values = getValues();
    const endpoint = values.endpoint;
    if (selectedExchange && endpoint && endpoint.trim() !== "") {
      const trimmedEndpoint = endpoint.trim().replace(/^\/+|\/+$/g, "");
      const endpointBaseUrl = new URL(endpoint, selectedExchange.base_url)
        .origin;

      let finalEndpoint;
      if (endpointBaseUrl === selectedExchange.base_url) {
        finalEndpoint = `${selectedExchange.base_url.replace(
          /\/+$/,
          ""
        )}/${trimmedEndpoint}`.replace(
          `${selectedExchange.base_url}/`,
          `${selectedExchange.base_url}`
        );
      } else if (!endpoint.includes("://")) {
        finalEndpoint = `${endpointBaseUrl}/${trimmedEndpoint}`;
      } else {
        finalEndpoint = endpoint;
      }

      const newExchange = {
        exchange_url_id: selectedExchange.id,
        endpoint: finalEndpoint,
        exchange_name: selectedExchange?.name,
      };

      if (
        !showExchangeLabels.some(
          (ex) => ex.exchange_url_id === selectedExchange.id
        )
      ) {
        setShowExchangeLabels([...showExchangeLabels, newExchange]);
        setSelectedExchange(null);
        reset({ exchange_url_id: "0", endpoint: "" });
      } else {
        dispatch(alertFailure("Exchange is already added in the list"));
      }
    } else {
      dispatch(alertFailure("Endpoints are required"));
    }
  };

  const updateExchangeUrlDataArray = () => {
    const exchangeArray = showExchangeLabels.map(
      ({ exchange_url_id, endpoint }) => ({ exchange_url_id, endpoint })
    );
    setValue("exchangeUrlDataArray", exchangeArray || []);
  };

  const handleRemoveExchange = (id: any) => {
    setShowExchangeLabels(
      showExchangeLabels.filter((exchange) => exchange.exchange_url_id !== id)
    );
  };

  const handleSelectChange = (e: any, onChange: any) => {
    onChange(e);
    const selectedExchange = exchangeList.find(
      (exchange: any) => exchange?.id === e.target.value
    );
    if (selectedExchange) {
      setSelectedExchange(selectedExchange);
    }
  };

  return (
    <Card
      className={classes.formControl}
      style={{ display: showTab ? "inherit" : "none", padding: "20px" }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <label className={classes.formControlLabel}>
          Select Exchange to Connect
        </label>
        <Controller
          control={control}
          name="exchange_url_id"
          rules={{ required: "Select an exchange." }}
          defaultValue="0"
          render={(field) => {
            const { value, onChange } = field;
            return (
              <Select
                {...field}
                labelId="exchange_url_id"
                id="exchange_url_id"
                name="exchange_url_id"
                fullWidth
                variant="outlined"
                onChange={(e) => handleSelectChange(e, onChange)}
              >
                <MenuItem value="0" disabled>
                  Select Any Option
                </MenuItem>
                {exchangeList &&
                  exchangeList
                    .filter((exchange) => exchange.isActive === 1)
                    .map((exchange: any, index: number) => (
                      <MenuItem value={exchange.id} key={index + exchange.id}>
                        {exchange.name.toUpperCase()}
                      </MenuItem>
                    ))}
              </Select>
            );
          }}
        />

        {selectedExchange && (
          <div>
            <p
              style={{
                margin: "5px",
                fontSize: "0.875rem",
                color: "#666",
                display: "block",
              }}
            >
              <strong> Hint: </strong> {selectedExchange.base_url}/token-name e.g ltt, cgpt
             
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Controller
                control={control}
                name="endpoint"
                defaultValue=""
                placeholder="Enter the endpoint (e.g., /ltt or /v1/cgpt)"
                rules={{ required: "Endpoint is required." }}
                render={(field) => (
                  <TextField {...field} variant="outlined" fullWidth />
                )}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ height: "56px" }}
                onClick={handleAddExchange}
              >
                Add
              </Button>
            </div>
          </div>
        )}
      </div>

      <p className={classes.formErrorMessage}>
        {renderError(errors, "exchange_url_id")}
      </p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {showExchangeLabels?.map((exchange: any) => (
          <Card
            key={exchange?.exchange_url_id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              minWidth: "30px",
            }}
          >
            <div>
              <p style={{ margin: 0 }}>{exchange?.exchange_name}</p>
            </div>
            <IconButton
              onClick={() => handleRemoveExchange(exchange?.exchange_url_id)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Card>
        ))}
      </div>
    </Card>
  );
}

export default React.memo(PoolExchangeConnect);
