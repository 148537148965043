import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import ImageUploader from "../PoolCreate/Components/ImageUploader";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  addSocailStats,
  updateSocailStatsbyID,
} from "../../request/social-stats";

const SocialMediaForm = () => {
  const [image, setImage] = useState("");
  const location = useLocation();
  // @ts-ignore
  const { socailStateLog } = location.state || {};
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      socialMediaName: "",
      subtitle: "",
      iconArrow: "",
      socialStatsType: "",
      socialStatsCount: "",
    },
  });

  const handleFileSelect = (file: any) => {
    setImage(file);
  };

  const onSubmit = async (data: any) => {
    const payload: any = {
      socialMediaName: data?.socialMediaName || '',
      socialImg: image ,
      subtitle: data?.subtitle,
      iconArrow: data?.iconArrow,
      socialStats: {
        type: data?.socialStatsType,
        count: data?.socialStatsCount,
      }
    };



    try {
      const response = id
        ? await updateSocailStatsbyID(payload, Number(id))
        : await addSocailStats(payload);

      if (response?.status === 200) {
        dispatch(alertSuccess("Saved successfully"));
        // history.push("/dashboard/social-media-list");
      } else {
        dispatch(alertFailure(response.message || "Failed to save data"));
      }
      history.push("/dashboard/social-media-stats");
    } catch (error) {
      console.error(error);
      dispatch(alertFailure("An error occurred while saving the data."));
    }
  };

  useEffect(() => {
    if (id && socailStateLog) {
      const { socialMediaName, socialImg, subtitle, iconArrow, socialStats } =
        socailStateLog;
      // Set values in the form
      setValue("socialMediaName", socialMediaName || "");
      setValue("subtitle", subtitle || "");
      setValue("iconArrow", iconArrow || "");
      setValue("socialStatsType", socialStats.type);
      setValue("socialStatsCount", socialStats.count);

      // Handle the image
      setImage(socialImg || "");
    }
  }, [id, socailStateLog, setValue]);

  return (
    <DefaultLayout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 gap-6"
        style={{
          gap: "15px",
        }}
      >
        {/* Social Media Name */}
        <Row style={{}}>
          <Col xs="6">
            <FormControl fullWidth>
              <label htmlFor="socialMediaName" className="mb-2 font-semibold">
                Social Media Name
              </label>
              <TextField
                disabled
                id="socialMediaName"
                name="socialMediaName"
                placeholder="Enter Social Media Name (e.g., Facebook)"
                variant="outlined"
                inputRef={register}
                // error={!!errors.socialMediaName}
                // helperText={errors.socialMediaName?.message}
              />
            </FormControl>
          </Col>

          <Col xs="6">
            {/* Subtitle */}
            <FormControl fullWidth>
              <label htmlFor="subtitle" className="mb-2 font-semibold">
                Subtitle
              </label>
              <TextField
                disabled
                id="subtitle"
                name="subtitle"
                placeholder="Enter Subtitle (e.g., Join our)"
                variant="outlined"
                inputRef={register}
              />
            </FormControl>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "10px",
          }}
        >
          <Col
            xs="12"
            style={{
              marginTop: "10px",
            }}
          >
            {/* Social Stats Type */}
            <FormControl fullWidth>
              <label htmlFor="socialStatsType" className="mb-2 font-semibold">
                Follower Count Label
              </label>
              <TextField
                disabled
                id="socialStatsType"
                name="socialStatsType"
                placeholder="Enter Follower Count Label (e.g., members)"
                variant="outlined"
                inputRef={register}
                // error={!!errors.socialStatsType}
                // helperText={errors.socialStatsType?.message}
              />
            </FormControl>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "10px",
          }}
        >
          <Col xs="6">
            {/* Social Stats Count */}
            <FormControl fullWidth>
              <label htmlFor="socialStatsCount" className="mb-2 font-semibold">
                Follower Count
              </label>
              <TextField
                id="socialStatsCount"
                name="socialStatsCount"
                placeholder="Enter Follower Count (e.g., 750K+)"
                variant="outlined"
                inputRef={register}
                // error={!!errors.socialStatsCount}
                // helperText={errors.socialStatsCount?.message}
              />
            </FormControl>
          </Col>
          <Col xs="6">
            {/* Social Stats Count */}
            <FormControl fullWidth>
              <label htmlFor="iconArrow" className="mb-2 font-semibold">
                URL
              </label>
              <TextField
                id="iconArrow"
                name="iconArrow"
                placeholder="Enter Icon Arrow URL"
                variant="outlined"
                inputRef={register}
              />
            </FormControl>
          </Col>
        </Row>
        {/* Logo Upload */}
        {/* <FormControl
          fullWidth
          style={{
            marginTop: "10px",
          }}
        >
          <label className="mb-2 font-semibold">Social Media Logo</label>
          <ImageUploader
            width="30%"
            onFileSelect={handleFileSelect}
            initialSrc={image}
          />
        </FormControl> */}

        {/* Actions */}
        <div
          className="mt-3 "
          style={{
            gap: "10px",
            display: "flex",
          }}
        >
          <Button
            variant="contained"
            color="default"
            onClick={() => history.push("/dashboard/social-media-stats")}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {id && socailStateLog ? "Update" : "Submit"}
          </Button>
        </div>
      </form>
    </DefaultLayout>
  );
};

export default withRouter(SocialMediaForm);
