import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useCommonStyle } from "../../../../styles";
import { exportIdoData, getIDOReport } from "../../../../request/participants";
import useGetList from "../hooks/useGetList";
import Pagination from "@material-ui/lab/Pagination";
import useStylesTable from "./style_table";
import { currencyInfo, etherscanRoute } from "../../../../utils";
import Link from "@material-ui/core/Link";
import { Button } from "@material-ui/core";
import BigNumber from "bignumber.js";

function PostIdoReport(props: any) {
  const commonStyle = useCommonStyle();
  const classesTable = useStylesTable();
  const { poolDetail } = props;
  const {
    rows,
    searchDelay,
    failure,
    loading,
    lastPage,
    currentPage,
    handlePaginationChange,
  } = useGetList({ poolDetail, handleSearchFunction: getIDOReport });

  const purchasedAmount = (purchased_amount: any) => {
    if (!purchased_amount) return "-";

    let purchasedAmount = purchased_amount;
    if (new BigNumber(purchasedAmount).lte(0)) return "-";

    const purchasedAmountText = `${new BigNumber(purchasedAmount || 0)
      .div(
        new BigNumber(10).pow(
          poolDetail && poolDetail.decimals ? poolDetail.decimals : 18
        )
      )
      .toFixed(3)} ${poolDetail?.symbol?.toUpperCase()}`;

    return purchasedAmountText;
  };

  const investedAmount = (invested_amount: any) => {
    if (!invested_amount) return "-";

    const currencyDecimal = (() => {
      if (poolDetail?.accept_currency === "eth") {
        return 18;
      }

      const network = poolDetail?.network_available;
      const currency = poolDetail?.accept_currency;

      if (
        network &&
        currency &&
        currencyInfo[network] &&
        currencyInfo[network][currency]
      ) {
        return currencyInfo[network][currency].decimal;
      }

      return undefined;
    })();
    let investedAmount = invested_amount;
    if (new BigNumber(investedAmount).lte(0)) return "-";

    const investedAmountText = `${new BigNumber(investedAmount || 0)
      .div(new BigNumber(10).pow(currencyDecimal || 18))
      .toFixed()} ${poolDetail?.accept_currency?.toUpperCase()}`;

    return investedAmountText;
  };
  return (
    <>
      {poolDetail?.campaign_status === "Claimable" ||
      poolDetail?.campaign_status === "Filled" ||
      poolDetail?.campaign_status === "Ended" ? (
        <div className={commonStyle.boxSearch}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              flexWrap: "wrap",
            }}
          >
            <div style={{ position: "relative", marginBottom: "1rem" }}>
              <input
                className={commonStyle.inputSearch}
                onChange={searchDelay}
                placeholder="Search"
                autoComplete="off"
              />

              <img
                src="/images/icon-search.svg"
                alt=""
                style={{ position: "absolute", right: "12px", bottom: "12px" }}
              />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => exportIdoData(poolDetail.id)}
              >
                Export Data
              </Button>
            </div>
          </div>

          <TableContainer
            component={Paper}
            className={`${commonStyle.tableScroll} ${classesTable.tableUserJoin}`}
          >
            <Table className={classesTable.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Wallet Address</TableCell>
                  <TableCell align="center">
                    {poolDetail.relationship_type === "Giveaway" &&
                    poolDetail.relationship_type ===
                      "Giveaway (Labs Incubation)"
                      ? "Token participated"
                      : "Token Bought"}
                  </TableCell>
                  {poolDetail.relationship_type !== "Giveaway" &&
                    poolDetail.relationship_type !==
                      "Giveaway (Labs Incubation)" && (
                      <TableCell align="center">Invested Amount</TableCell>
                    )}
                  {poolDetail.relationship_type !== "Giveaway" &&
                    poolDetail.relationship_type !==
                      "Giveaway (Labs Incubation)" && (
                      <TableCell align="center">Refunded Amount</TableCell>
                    )}
                  <TableCell align="center">Claimed Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <Link
                        href={etherscanRoute(row.wallet_address, poolDetail)}
                        target={"_blank"}
                      >
                        {row.wallet_address}
                      </Link>
                    </TableCell>

                    <TableCell align="center" component="th" scope="row">
                      {purchasedAmount(row?.purchased_amount)}
                    </TableCell>
                    {poolDetail.relationship_type !== "Giveaway" &&
                      poolDetail.relationship_type !==
                        "Giveaway (Labs Incubation)" && (
                        <TableCell align="center" component="th" scope="row">
                          {investedAmount(row?.invested_amount)}
                        </TableCell>
                      )}
                    {poolDetail.relationship_type !== "Giveaway" &&
                      poolDetail.relationship_type !==
                        "Giveaway (Labs Incubation)" && (
                        <TableCell align="center" component="th" scope="row">
                          {investedAmount(row?.total_refunded) || 0}
                        </TableCell>
                      )}

                    <TableCell align="center" component="th" scope="row">
                      {purchasedAmount(row?.total_claimed) || 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {failure && <p className={classesTable.errorMessage}>{failure}</p>}
            {!failure && (!rows || rows.length === 0) && !loading ? (
              <p className={classesTable.noDataMessage}>There is no data</p>
            ) : (
              <>
                {rows && lastPage > 1 && (
                  <Pagination
                    page={currentPage}
                    className={classesTable.pagination}
                    count={lastPage}
                    onChange={handlePaginationChange}
                  />
                )}
              </>
            )}
          </TableContainer>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Please wait until the swap period has ended.
        </div>
      )}
    </>
  );
}

export default PostIdoReport;
