// import React from 'react';
// import useStyles from "../style";
// import {renderErrorCreatePool} from "../../../utils/validate";

// function PoolName(props: any) {
//   const classes = useStyles();
//   const {
//     register, errors,
//     poolDetail,
//     isEditDisabled
//   } = props;
//   const renderError = renderErrorCreatePool;
//   // const isDeployed = !!poolDetail?.is_deploy;

//   return (
//     <>
//       <div className={classes.formControl}>
//         <label className={classes.formControlLabel}>Pool Name</label>
//         <input
//           type="text"
//           name="title"
//           defaultValue={poolDetail?.title}
//           ref={register({ required: true })}
//           maxLength={255}
//           className={classes.formControlInput}
//           disabled={isEditDisabled}
//           // disabled={isDeployed}
//           style = {{
//             backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
//           }}
//         />
//         <p className={classes.formErrorMessage}>
//           {
//             renderError(errors, 'title')
//           }
//         </p>
//       </div>
//     </>
//   );
// }

// export default PoolName;



// ***************************************************************************************
// SLUG CODE
// ***************************************************************************************

import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import { TITLE_SLUG } from "../../../constants";

function PoolName(props: any) {
  const classes = useStyles();
  const { poolDetail, setValue, register, isEditDisabled, watch, errors } =
    props;
  const renderError = renderErrorCreatePool;

  const title = watch("title"); 
  const [slug, setSlug] = useState(poolDetail?.slug || "");
  const [isSlugManual, setIsSlugManual] = useState(false); 

  useEffect(() => {
    if (title && !isSlugManual && !poolDetail?.slug) {
      const generatedSlug = title
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-]/g, "")
        .replace(/-+/g, "-")   
        .replace(/^-+/, "")    
        .replace(/-+$/, "");   

      setSlug(generatedSlug);
      setValue("slug", generatedSlug);
    }

    if (!title) {
      setSlug("");
    }
  }, [title, isSlugManual, poolDetail?.slug, setValue]);

  useEffect(() => {
    if (poolDetail?.slug && title !== poolDetail?.title) {
      setSlug(poolDetail.slug);
      setValue("slug", poolDetail.slug);
    }
  }, [poolDetail?.slug, poolDetail?.title, title, setValue]);

  useEffect(() => {
    if (slug && isSlugManual) {
      const generatedSlug = slug
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "")
            .replace(/\s+/g, "-")
            .replace(/-+/g, "-"); 

      setSlug(generatedSlug);
      setValue("slug", generatedSlug);
    }
  }, [slug, setValue, isSlugManual]);

  // handle auto generate slug
  const handleAutoGenerateSlug = () => {
    const generatedSlug = `${title
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")        
      .replace(/[^a-z0-9-]+/g, "") 
      .replace(/^[-]+|[-]+$/g, "")}-${Date.now()}`;
    setSlug(generatedSlug);
    setIsSlugManual(false);
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
    setIsSlugManual(true);  
  };
  return (
    <>
      {/* Pool Name */}
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Pool Name</label>
        <input
          type="text"
          defaultValue={poolDetail?.title}
          name="title"
          ref={register({ required: "Title is required", maxLength: 255 })}
          disabled={isEditDisabled}
          className={classes.formControlInput}
          style={{
            backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
          }}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "title")}
        </p>
      </div>

      {/* Slug */}
      {TITLE_SLUG && (
        <div className={classes.formControl}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <label className={classes.formControlLabel}>Slug</label>
            <span
              style={{
                color: "#ff4500",
                fontSize: "0.85rem",
                fontWeight: "600",
              }}
            >
              * Must be unique
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              name="slug"
              defaultValue={poolDetail.slug}
              value={slug} // Use slug state as value
              onChange={handleSlugChange} // Update slug on manual change
              ref={register({ required: "Slug is required", maxLength: 255 })}
              disabled={isEditDisabled}
              className={classes.formControlInput}
              style={{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
            />
            {/* <button
              onClick={handleAutoGenerateSlug}
              type="button"
              className={classes.autoSlug}
              style={{ marginLeft: "10px", padding: "5px 10px" }}
            >
              Auto
            </button> */}
          </div>
          <p className={classes.formErrorMessage}>
            {renderError(errors, "slug")}
          </p>
        </div>
      )}
    </>
  );
}

export default PoolName;

